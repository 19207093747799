import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ((err.status === 401 || err.status === 403 ) && this.authService.authenticated) {
                // auto logout if 401 response returned from api
               this.authService.signout();
               //location.reload(true);
               return ;
            }
            const error = err.statusText;
            return next.handle(request);
            //return throwError(error);
        }));
    }
}
