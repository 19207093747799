<div class="breadcrumb">
  <h1>Guichet unique</h1>
  <ul>
    <li></li>
    <li>Détails du compte</li>
  </ul>
</div>
<div class="separator-breadcrumb border-top"></div>
<div *ngIf="loading" class="d-flex text-center">
  <div class="loader-bubble loader-bubble-primary"></div>
</div>
<div *ngIf="!loading">
  <div class="d-flex flex-row justify-content-between mb-2">
    <small></small>
    <div>

      <button *ngIf="guard.pass(autority?.appro_agence)" (click)="transfert(transfertsolde)" class="btn btn-success float-righ">
        <i class="i-Down"></i> Transfert Solde Commission
      </button>
    </div>
  </div>
  <div class="row">
    <!-- ICON BG -->
    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="guard.pass(autority?.solde_cash)">
      <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div class="card-body text-center">
          <i class="i-Financial"></i>
          <div class="content">
            <p class="text-muted mt-2 mb-0">{{'GENERAL.CASH_BALANCE' | translate}} ({{entite.devise}})</p>
            <p class="text-primary text-24 line-height-1 mb-2">{{soldeCash | number}} </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="guard.pass(autority?.solde_cashAn)">
      <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div class="card-body text-center">
          <i class="i-Money-Bag"></i>
          <div class="content">
            <p class="text-muted mt-2 mb-0">{{'GENERAL.CASH_BALANCE' | translate}} ({{entite.devise}})</p>
            <p class="text-primary text-24 line-height-1 mb-2">{{soldeCashAn | number}} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="guard.pass(autority?.appro_agence)">
      <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div class="card-body text-center">
          <i class="i-Money-2"></i>
          <div class="content">
            <p class="text-muted mt-2 mb-0">{{'GENERAL.BALANCE' | translate}}({{entite.devise}})</p>
            <p class="text-primary text-24 line-height-1 mb-2">{{currentAgence?.solde | number}} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="guard.pass(autority?.appro_agence)">
      <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div class="card-body text-center">
          <i class="i-Money-2"></i>
          <div class="content">
            <p class="text-muted mt-2 mb-0">Solde Commission({{entite.devise}})</p>
            <p class="text-primary text-24 line-height-1 mb-2">{{currentAgence?.soldeCommission | number}} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6"  *ngIf="guard.pass(autority?.appro_entite)">
      <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div class="card-body text-center">
          <i class="i-Money-2"></i>
          <div class="content">
            <p class="text-muted mt-2 mb-0">{{'GENERAL.BALANCE' | translate}}({{entite.devise}})</p>
            <p class="text-primary text-24 line-height-1 mb-2">{{soldeEntite | number}} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-6"  *ngIf="guard.pass(autority?.appro_comm_entite)">
      <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div class="card-body text-center">
          <i class="i-Money-2"></i>
          <div class="content">
            <p class="text-muted mt-2 mb-0">{{'GENERAL.BALANCE_COM_ENT' | translate}}({{entite.devise}})</p>
            <p class="text-primary text-24 line-height-1 mb-2">{{soldeCommission | number}} </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #transfertsolde>
  <div  >
    <div class="modal-header">
      <h3 class="modal-title titleH" id="modal-basic-title">Transfert Solde commission</h3>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="d-flex text-center" *ngIf="loading">
      <div class="loader-bubble loader-bubble-primary"></div>
    </div>
    <div class="card-body ">

      <div class="row justify-content-center">
        <div class="col-md-12 col-sm-12">
          <form [formGroup]="codPinForm">
            <div class="form-group">
              <p class="text-uppercase text-muted">Montant(*)</p>
              <input type="number"  formControlName="montant" class="form-control"  placeholder="Montant(*)" />
            </div>
          </form>
        </div>
      </div>
      <!--      [autocomplete]="'off'"-->
    </div>
    <div class="modal-footer text-right" >
      <button class="btn btn-default btn-rounded mr-2" aria-label="Close" (click)="close()">Annuler</button>
      <button class="btn btn-success btn-rounded mr-2"  type="button"   [disabled]="loading"   (click)="validation()" >{{ loading? 'Traitement en cours ...' : 'Valider'}}</button>
    </div>
  </div>
</ng-template>
