import {Component, OnInit, HostListener, OnDestroy} from '@angular/core';
import { NavigationService, IMenuItem, IChildItem } from '../../../../services/navigation.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Utils } from '../../../../utils';
import {PermissionsService} from '../../../../services/permissions.service';
import {Constantes} from "../../../../constantes";
import {LocalStoreService} from "../../../../services/local-store.service";

@Component({
  selector: 'app-sidebar-large',
  templateUrl: './sidebar-large.component.html',
  styleUrls: ['./sidebar-large.component.scss']
})
export class SidebarLargeComponent implements OnInit,OnDestroy {

    selectedItem: IMenuItem;

	nav: IMenuItem[];

	constructor(
		public router: Router,
    private store: LocalStoreService,
		public navService: NavigationService,
    private _permissionService: PermissionsService
	) {
  }

	ngOnInit() {
		this.updateSidebar();
		// CLOSE SIDENAV ON ROUTE CHANGE
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((routeChange) => {
				this.closeChildNav();
				if (Utils.isMobile()) {
					this.navService.sidebarState.sidenavOpen = false;
				}
			});

		this.navService.menuItems$
			.subscribe((items) => {
			  // console.log("items: ",items);
        this.nav = items;
				this.setActiveFlag();
			});
	}

	selectItem(item) {
		this.navService.sidebarState.childnavOpen = true;
		this.selectedItem = item;
		this.setActiveMainItem(item);
	}
	closeChildNav() {
		this.navService.sidebarState.childnavOpen = false;
		this.setActiveFlag();
	}

	onClickChangeActiveFlag(item) {
		this.setActiveMainItem(item);
	}
	setActiveMainItem(item) {
		this.nav.forEach(item => {
			item.active = false;
		});
		item.active = true;
	}

	setActiveFlag() {
		if (window && window.location) {
      const activeRoute = window.location.hash || window.location.pathname;
			this.nav.forEach(item => {
				item.active = false;
				if (activeRoute.indexOf(item.state) !== -1) {
          this.selectedItem = item;
					item.active = true;
				}
				if (item.sub) {
					item.sub.forEach(subItem => {
            subItem.active = false;
						if (activeRoute.indexOf(subItem.state) !== -1) {
              this.selectedItem = item;
              item.active = true;
            }
            if (subItem.sub) {
              subItem.sub.forEach(subChildItem => {
                if (activeRoute.indexOf(subChildItem.state) !== -1) {
                  this.selectedItem = item;
                  item.active = true;
                  subItem.active = true;
                }
              });
            }
					});
				}
      });
		}
    }

	updateSidebar() {
		if (Utils.isMobile()) {
			this.navService.sidebarState.sidenavOpen = false;
			this.navService.sidebarState.childnavOpen = false;
		} else {
			this.navService.sidebarState.sidenavOpen = true;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateSidebar();
	}

  ngOnDestroy(): void {
	  this.nav = [];
	  // @ts-ignore
    // @ts-ignore
    this.navService.menuItems$.source._value = [];
    // @ts-ignore
    // this._permissionService.destroy();
  }

  getNameEntity(item: IMenuItem|IChildItem,attribut:string='name_entite') {
    const entite = this.store.getItem(Constantes.CURRENT_CODE_ENTITY);
    return item[attribut]?.find((nameNemu)=>{
      return nameNemu?.code ==entite
    })?.value || item?.name
  }

}
