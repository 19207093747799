import { Component, ViewChild, ElementRef, EventEmitter, AfterViewInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.sass']
})
export class RecaptchaComponent implements AfterViewInit {
  @ViewChild('recaptcha') recaptchaElement: ElementRef;
  @Output() checkedRecaptcha = new EventEmitter<boolean>();

  constructor(
    private _authService: AuthService
  ) { }

  ngAfterViewInit(): void {
    this.addRecaptchaScript();
  }

  renderReCaptch() {
    // window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
    //   'sitekey' : '6LdP7FoaAAAAAH5d33Vsgco0qGIo_54xqo-_4ZR_',
    //   'callback': (response) => {
    //       //this.checkedRecaptcha.next(response)
    //     this._authService.validateRecaptcha(response)
    //       .subscribe(res => {
    //         if (res['success']) {
    //           this.checkedRecaptcha.emit(true);
    //         } else {
    //           this.checkedRecaptcha.emit(false);
    //         }
    //     }, error => {
    //         console.log(error)
    //     })
    //   }
    // });
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

}
