<div class="modal-header bg-gray-100">
  <h3 class="modal-title" id="modal-basic-title">Demande d'approvisionnement</h3>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="approvForm">
  <div class="modal-body">
    <div class="row form-group">
      <div class="col-md-3">
        <label>Montant({{data.devise}})<span class="text-danger">*</span></label>
<!--        <p class="text-muted">Montant inscrit sur le reçu de virement.</p>-->
      </div>
      <div class="col-md-9 px-4">
        <input type="number" [min]="0" class="form-control form-control-rounded" formControlName="montant" />
        <p class="text-small text-danger mt-1" *ngIf="approvForm.controls.montant.errors?.required && (approvForm.controls.montant.dirty ||approvForm.controls.montant.touched)">Veuillez saisir le montant </p>
      </div>
    </div>
    <div class="row form-group" >
      <div class="col-md-3">
        <label class="text-right">Documents</label>
<!--        <p class="text-muted">Reçu de virement de votre banque</p>-->
      </div>
      <div class="col-md-9 px-4">
        <div ngx-dropzone [expandable]="true" (change)="onSelect($event)">
          <ngx-dropzone-label>
            <div>
              <h4> Cliquer ou deposer les documents  </h4>
            </div>
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of approvForm.value.documents" ngProjectAs="ngx-dropzone-preview"  [file]="f"
                                      [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>

          </ngx-dropzone-preview>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer bg-gray-100">
    <div class="row">
      <div class="col-md-12">
        <a class="btn btn-outline-default btn-rounded" (click)="activeModal.dismiss('Cross click')">Annuler</a>
        <button class="btn btn-primary btn-rounded ml-1" [disabled]="approvForm.invalid || loading" type="submit"  (click)="validate()" >Envoyer</button>
      </div>
    </div>
  </div>
</form>

