import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import { Printer, Filter, Download, Plus, Check,
  Delete, TrendingUp, ArrowUpLeft, Unlock, 
  Edit3, Info, X, MoreVertical}
  from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
  Printer,
  Filter,
  Download,
  Plus,
  Check,
  Delete,
  TrendingUp,
  ArrowUpLeft,
  Unlock,
  Edit3,
  Info,
  X,
  MoreVertical
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }