import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AgenceService } from '../../services/agence.service';
import { Partner } from '../../models/partner';
import { Constantes } from '../../constantes';

@Component({
  selector: 'app-autocomplete-agence',
  templateUrl: './autocomplete-agence.component.html',
  styleUrls: ['./autocomplete-agence.component.scss']
})
export class AutocompleteAgenceComponent implements OnInit {
  agences = [];
  show: boolean = false;
  name:string = '';
  @Output() selected = new EventEmitter<number>();
  @Input() defaultValue:string;
  @Input() customUrl:string;
  
  constructor(
    private agenceService: AgenceService
  ) { }

  ngOnInit() {
    this.name = this.defaultValue
    this.defaultList();
  }

  //Search roles
  search(event?) {
    const term = event.target.value; 
    if(term) {
      this.agenceService.search(term, this.customUrl)
      .subscribe(res => {
        this.agences = res[Constantes.HYDRA_MEMBER];
      })
    } else {
      this.defaultList();
    }
  }

  defaultList() {
    this.agenceService.search('', this.customUrl)
    .subscribe(res => {
      this.agences = res[Constantes.HYDRA_MEMBER];
    })
  }
 
  openSuggestions(){
    this.show = true;
  }

  closeSuggestions() {
    this.show = false;
  }

  selectAgence(index) {
    const agence = this.agences[index];
    this.name = agence.nom;
    this.selected.emit(agence.code)
    this.show = false;
  }
}
