// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const BASE_API = 'https://services.pdg-dev.com';
export const environment = {
  allowAll:false,
  production: false,
  apiAuthUrl: 'https://auth-services.nt-albey.ne/api',
  apiUrl: BASE_API + '',
  apiAdminUrl: 'https://admin-services.nt-albey.ne/api',
  apiReseauUrl: 'https://reseau-services.nt-albey.ne/api',
  apiTransfertUrl:'https://transfert-services.nt-albey.ne/api',
  apiWalletUrl: 'https://wallet-services.nt-albey.ne/api',
  apiLogsUrl: 'https://logs-services.nt-albey.ne/api',
  apiMassPaymentUrl: 'https://mass-payment-services.nt-albey.ne/' ,
  apiComplianceUrl: 'https://fraude-services.bgdigital.tech',
  apiSimBotUrl: 'https://services.pdg-dev.com/simbot_services_v1/api',
  urlFront:'https://reseau.nt-albey.ne/#/sessions/signin',
  apiParterUrl: 'https://partners-services.niger-telecom.tech/api',
  apiAmlUrl: 'https://services.sengup.com/aml/api',
  urlFrontstandard:'https://pdg.sn/adminpos/#/sessions/signin',
  urlFrontforotNT:'https://reseau.nt-albey.ne/#/sessions/forgot',
  urlFrontfirstNT:'https://reseau.nt-albey.ne/#/sessions/updatePwd',
  urlFrontforot:'https://pdg.sn/adminpos/#/sessions/forgot',
  urlFrontfirst:'https://rpdg.sn/adminpos/#/sessions/updatePwd',
  // https://mass-payment-services.niger-telecom.tech/
  // https://osticket-services.niger-telecom.tech/
  //   https://partners-services.niger-telecom.tech

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
