<div id="print-area">


   <div class="col-md-12 test">

    </div>
  <div class="col-md-12 test">,.

    </div>
  <div class="col-md-12 test2">

  </div>
</div>
<button (click)="test()">
test
</button>
