import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LocalStoreService } from "./local-store.service";
import { Constantes } from '../constantes';
import { Agence } from 'src/app/shared/models/agence.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AgenceService {
  private codeClient;
  private agenceSource = new BehaviorSubject(new Agence({}));
  currentAgence = this.agenceSource.asObservable();

  constructor(
    private http: HttpClient,
    private store: LocalStoreService,
  ) {
    this.codeClient = this.store.getItem(Constantes.CURRENT_CODE_ENTITY);
  }

  getCodeClient() {
    return this.codeClient;
  }

  getAgences(page = 1, perPage = 10) {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/point_de_ventes?_page=${page}`);
  }

  getAgencesAll(page = 1, perPage = 10) {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/agences/all?_page=${page}&codeEntite=${this.codeClient}`);
  }

  getPartenaireByCode(code, page = 1, perPage = 10) {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/partenaires?_page=${page}&operateur.code=${code}`);
  }

  operateursServices() {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/operateurs`);
  }

  operateursAllServices() {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/operateurs/all`);
  }

  getAgencesAllWithoutPagination() {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/agences/all`);
  }

  public async getSelectList(url: string, text: string[] | string, logos: string = null, id: string = "id") {
    if (url == null)
      url = `${environment.apiReseauUrl}/agences/all?codeEntite=${this.codeClient}`;
    let res: any = await this.http.get<any>(url).toPromise();
    let data;
    if (res['hydra:member']) {
      data = res['hydra:member'];
    } else {
      data = res;
    }

    let options: { [x: string]: any; }[] = [];

    let data_ = data.map(function (item: { [x: string]: any; }, index: any) {
      let option = item;
      if (typeof text === typeof []) {
        let txt = '';
        for (var i = 0; i < text.length; i++) {
          if (text[i].indexOf(".") != -1) {
            let _ = text[i].split('.');
            txt += item[_[0]][_[1]];
          } else if (item[text[i]]) {
            txt += item[text[i]];
          } else {
            txt += text[i];
          }
          if (i < text.length - 1) {
            if (logos != null) {
              txt += " " + logos + " ";
            } else {
              txt += " ";
            }
          }
        }
        option.text = txt;
        option.id = item[id];
      } else {
        if (text.indexOf(".") != -1) {
          if (typeof text === "string") {
            let _ = text.split('.');
            let txt = item[_[0]][_[1]] + " ";
            option.text = txt;
            option.id = item[id];
          }
        } else {
          // @ts-ignore
          option.text = item[text];
          option.id = item[id];
        }
      }
      options.push(option);
    });
    return options;
  }

  getCommercialPoints(page = 1, query) {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/enrolements/commerciaux?_page=${page}${query}`, {});
  }

  getPieceConformite(page = 1, query) {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/piece_conformites?_page=${page}${query}`, {});
  }

  getPointDeventes(query = '', page = 1) {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/point_de_ventes?_page=${page}${query}`);
  }

  getCooperatives(query = '', page = 1) {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/cooperatives?_page=${page}${query}`);
  }

  getOneA(query = '') {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/point_de_ventes${query}`);
  }

  search(term, customUrl = '') {
    const url = customUrl || 'point_de_ventes'
    if (term) {
      return this.http.get<Agence[]>(`${environment.apiReseauUrl}/${url}?nom=${term}`)
    } else {

      return this.http.get<Agence[]>(`${environment.apiReseauUrl}/${url}?_page=1`)
    }
  }

  getEntite() {
    return this.store.getItem(Constantes.DETAILS_ENTITE);
  }

  getAgence(id) {
    return this.http.get<any[]>(environment.apiReseauUrl + '/point_de_ventes/' + id);
  }

  getCooperativeByid(id) {
    return this.http.get<any[]>(environment.apiReseauUrl + '/cooperatives/' + id);
  }

  getPiecebyId(id) {
    return this.http.get<any[]>(environment.apiReseauUrl + '/piece_conformites/' + id);
  }

  getAgenceParent(id) {
    return this.http.get<any[]>(environment.apiReseauUrl + '/point_de_ventes/' + id + '/parent');
  }

  getReseau(id) {
    return this.http.get<any[]>(environment.apiReseauUrl + '/point_de_ventes/' + id + '/childrens');
  }

  getPage(data) {
    const temp = parseInt(data.page) + 1
    // approvisionnement_agences?agence.id=4&_page=1;
    return '?agence.id=' + data.agenceId + '&_page=' + temp;
  }

  getApproAgence(agenceId) {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/approvisionnement_agences?agence.id=${agenceId}`);
  }

  saveAgence(agence) {
    if (agence.status) {
      agence.status = 1;
    } else agence.status = 0;
    if (agence.id) {
      return this.http.put<Agence>(`${environment.apiReseauUrl}/point_de_ventes/${agence.id}`, agence);
    } else {
      return this.http.post<Agence>(`${environment.apiReseauUrl}/point_de_ventes`, agence);
    }
  }

  saveCooperative(cooperatives) {

    if (cooperatives.id) {
      return this.http.put<any>(`${environment.apiReseauUrl}/cooperatives/edit/${cooperatives.id}`, cooperatives);
    } else {
      return this.http.post<any>(`${environment.apiReseauUrl}/cooperatives`, cooperatives);
    }
  }

  saveEnrollement(data) {
    return this.http.post<any[]>(`${environment.apiReseauUrl}/caisses/commercial/ajout`, data);
  }

  savePiececonformite(code_caisse, data) {
    return this.http.post<any[]>(`${environment.apiReseauUrl}/piece_conformites/${code_caisse}`, data);
  }

  updatePiececonformite(id, data) {
    return this.http.put<any[]>(`${environment.apiReseauUrl}/piece_conformites/${id}`, data);
  }

  rejeterPiece(id, motif) {
    return this.http.put<any>(`${environment.apiReseauUrl}/piece_conformites/rejeter/${id}`, motif)
  }

  activerPiece(id, motif = []) {
    return this.http.put<any>(`${environment.apiReseauUrl}/piece_conformites/valider/${id}`, motif)
  }

  approvisAgence(appro) {
    return this.http.post<any>(`${environment.apiReseauUrl}/approvisionnement_agences`, appro);
  }

  deleteAgence(id) {
    return this.http.delete<any[]>(environment.apiReseauUrl + '/point_de_ventes/' + id);
  }

  //Liste des approvisions pour la caisse
  getProvisions(caisseId) {
    return this.http.get<any[]>(environment.apiReseauUrl + `/approvisionnement_caisses?caisse.id=${caisseId}`);
  }

  //Listes des provision pour une agence
  getAgenceProvisions(agenceId) {
    return this.http.get<any[]>(environment.apiReseauUrl + `/approvisionnement_agences?agence.id=${agenceId}`);
  }

  //Approvisionner une agence
  agenceProvisions(agenceId) {
    return this.http.get<any>(`${environment.apiReseauUrl}/approvisionnement_agences?agence.id=${agenceId}`);
  }

  // Bilan transactions agence
  getAgenceBilanTransactions(codeAgence) {
    return this.http.get<any[]>(environment.apiReseauUrl + '/transactions/bilan?caisse.pointDeVente.code=' + codeAgence);
  }

  // Agence balance
  getBalance() {
    return this.http.get<any[]>(environment.apiReseauUrl + '/reporting/point_de_ventes/solde');
  }

  //Cahrger commmission agence
  geBilanCommisionsAgence(codeAgence) {
    return this.http.get<any[]>(environment.apiReseauUrl + '/commissions/bilan?caisse.pointDeVente.code=' + codeAgence);
  }

  //Load caisse
  getCaisse(id) {
    return this.http.get<any[]>(environment.apiReseauUrl + '/caisses/' + id);
  }

  getAllCaisses() {
    return this.http.get<any[]>(environment.apiReseauUrl + '/caisses/all');
  }

  //Load transactions
  getAgenceTransactions(codeAgence) {
    return this.http.get<any[]>(environment.apiReseauUrl + '/transactions/entite?caisse.pointDeVente.code=' + codeAgence);
  }


  //Get agence by code
  getAgenceByCode(code) {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/point_de_ventes?codeEntite=${this.codeClient}&code=${code}`);
  }

  //Récupéres les départements
  getDepartements() {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/departements`);
  }

  //Récupéres les départements
  getCommunes() {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/communes`);
  }

  changeEntity(agence: Agence) {
    this.agenceSource.next(agence);
  }

  findAgenceByCode(codeAgence) {
    return this.http.get<any>(`${environment.apiReseauUrl}/point_de_ventes?codeEntite=${this.codeClient}&code=${codeAgence}`);
  }

  getSoldeAgence() {
    return this.http.get<any>(`${environment.apiReseauUrl}/point_de_ventes/connecte/solde`);
  }

  getRegions(filteredByPays = '') {
    return this.http.get<Agence[]>(`${environment.apiReseauUrl}/regions${filteredByPays}`);
  }

  exportDataFile(type, query) {
    return this.http.get(`${environment.apiReseauUrl}/point_de_ventes/${type}?true=true${query}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  setStatusDistribution(id, status) {
    return this.http.put<any>(environment.apiWalletUrl + `/distribution_carte_entites/${id}/validate`, status);
  }

  setStatusRetour(id, status) {
    return this.http.put<any>(environment.apiWalletUrl + `/retour_carte_agences/${id}/validate`, status);
  }

  getDistributionCardEntity(page, query) {
    return this.http.get<any[]>(`${environment.apiWalletUrl}/distribution_carte_entites?_page=${page}${query}`);
  }

  setStatusDistributionAgence(id, status) {
    return this.http.put<any>(environment.apiWalletUrl + `/distribution_carte_agences/${id}/validate`, status);
  }

  getDistributionCardAgence(page, query) {
    return this.http.get<any[]>(`${environment.apiWalletUrl}/distribution_carte_agences?_page=${page}${query}`);
  }

  async disableEnableStateClient(object) {
    return await this.http.post<any>(`${environment.apiWalletUrl}/clients/etat`, object).toPromise();
  }

  async EnableStateClient(id, object) {
    return await this.http.put<any>(`${environment.apiWalletUrl}/clients/activer/${id}`, object).toPromise();
  }

  accToAccTransfer(params) {
    return this.http.post<any>(`${environment.apiReseauUrl}/mouvement_agences`, params)
  }

  getAccToAccTransfers(page = 1) {
    return this.http.get<any>(`${environment.apiReseauUrl}/mouvement_agences?_page=${page}`)
  }

  acceptAccToAccTransfers(id) {
    return this.http.put<any>(`${environment.apiReseauUrl}/mouvement_agences/valider/${id}`, {})
  }

  cancelAccToAccTransfers(id) {
    return this.http.put<any>(`${environment.apiReseauUrl}/mouvement_agences/annuler/${id}`, {})
  }

  exportEnrollement(format, query) {
    return this.http.get(`${environment.apiReseauUrl}/enrolements/commerciaux/${format}?true=true${query}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  transfertComm(data) {
    return this.http.post<any[]>(`${environment.apiReseauUrl}/point_de_ventes/commission_to_account`, data);
  }
}
