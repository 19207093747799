import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-askia',
  templateUrl: './askia.component.html',
  styleUrls: ['./askia.component.scss']
})
export class AskiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
test(){
    window.print()
}
}
