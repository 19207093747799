import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApprovService } from 'src/app/shared/services/approv.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
interface DialogData {
  type?: string;
  devise?: string;
}

@Component({
  selector: 'app-approvis-dialog',
  templateUrl: './approvis-dialog.component.html',
  styleUrls: ['./approvis-dialog.component.scss']
})
export class ApprovisDialogComponent implements OnInit {
  data: DialogData;
  approvForm: FormGroup;
  loading: boolean;
  fileName;

  constructor(
    private _formBuilder:FormBuilder,
    private _approvService:ApprovService,
    public activeModal:NgbActiveModal,
    private _toastrService:ToastrService
  ) { }

  ngOnInit(): void {
    if(this.data.type == 'agence') {
      this.approvForm = this._formBuilder.group({
        montant: new FormControl('', Validators.required)
      })
    } else {
      this.approvForm = this._formBuilder.group({
        montant: new FormControl('', Validators.required),
        document: new FormControl('', Validators.required),
        fileType: new FormControl('', Validators.required)
      });
    }
  }

  validate() {
    this.loading = true;
    if(this.data.type == 'agence') {
      this._approvService.approvisionnerAgence(this.approvForm.value)
      .subscribe(res => {
        this.loading = false;
        this.activeModal.close({success:true});
      }, error => {
        this.loading = false;
        this._toastrService.error("Echec lors de l'envoi de la demande. Veuillez réessayer.", "Demande d'approvisionnement")
        console.log(error);
      })
    } else {
      this._approvService.approvisionnerEntite(this.approvForm.value)
      .subscribe(res => {
        this.loading = false;
        this.activeModal.close({success:true});
      }, error => {
        this.loading = false;
        this._toastrService.error("Echec lors de l'envoi de la demande. Veuillez réessayer.", "Demande d'approvisionnement")
        console.log(error);
      })
    }
  }

  onFileChange(event) {
    this.fileName = event.target.files[0]['name'];
    const file = event.target.files[0];
    this.approvForm.controls['fileType'].setValue(file.type);
    const reader = new FileReader();
    reader.readAsDataURL( file);
    reader.onload = () => {
      const source: string = reader.result as string;
      this.approvForm.controls.document.setValue(source);
    }
  }

}
