<div class="modal-header bg-gray-100">
  <h3 class="modal-title" id="modal-basic-title">Demande d'approvisionnement</h3>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="approvForm">
  <div class="modal-body">
    <div class="row form-group">
      <div class="col-md-3">
        <label>Montant({{data.devise}})<span class="text-danger">*</span></label>
        <p class="text-muted">Montant inscrit sur le reçu de virement.</p>
      </div>
      <div class="col-md-9 px-4">
        <input type="number" [min]="0" class="form-control form-control-rounded" formControlName="montant" />
        <p class="text-small text-danger mt-1" *ngIf="approvForm.controls.montant.errors?.required && (approvForm.controls.montant.dirty || approvForm.controls.montant.touched)">Veuillez saisir le montant </p>
      </div>
    </div>
    <div class="row form-group" *ngIf="data.type == 'entite'">
      <div class="col-md-3">
        <label class="text-right">Document<span class="text-danger">*</span></label>
        <p class="text-muted">Reçu de virement de votre banque</p>
      </div>
      <div class="col-md-9 px-4">
        <div class="custom-file">
          <input type="file" (change)="onFileChange($event)" class="custom-file-input" id="approvis-doc"/>
          <label class="custom-file-label" for="logoUpload" *ngIf="!approvForm.controls.document.value">Charger</label>
          <label class="custom-file-label" for="logoUpload" *ngIf="approvForm.controls.document.value">Document chargé - {{fileName|slice:0:80}}</label>
        </div>
         <p class="text-small text-danger mt-1" *ngIf="approvForm.controls.document.errors?.required && (approvForm.controls.document.dirty || approvForm.controls.document.touched)">Veuillez charger le reçu de virement.</p>
      </div>
    </div>
  </div>
  <div class="modal-footer bg-gray-100">
    <div class="row">
      <div class="col-md-12">
        <a class="btn btn-outline-default btn-rounded" (click)="activeModal.dismiss('Cross click')">Annuler</a>
        <button class="btn btn-primary btn-rounded ml-1" [disabled]="approvForm.invalid || loading" type="submit"  (click)="validate()" >Envoyer</button>
      </div>
    </div>
  </div>
</form>
