import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import {LocalStoreService} from "../services/local-store.service";
import {Constantes} from "../constantes";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService ,private _localStoreService: LocalStoreService,private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available\let
      let user= this._localStoreService.getItem(Constantes.DGC_CURRENT_USER);
        let jwtToken = this.authService.getJwtToken();

        if (jwtToken && this.authService.authenticated) {
            request = request.clone({
                setHeaders: {
                  'X-AUTH-TOKEN': `${jwtToken}`,
                }
            });
          if(user.changePassword && request.url !== `${environment.apiAuthUrl}/users/password/change` && !this.authService.authenticated){
            this.router.navigateByUrl('sessions/updatePwd');
          }
        }
        return next.handle(request);
    }
}
