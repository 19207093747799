<div  class=" hidden-xs d-flex align-items-center"  >
  <div class="d-flex flex-row justify-content-around" *ngIf="guard.pass(autority?.solde_cash)">
    <span class="text-small mr-1 pt-1">{{'GENERAL.CASH_BALANCE' | translate}}:</span>
    <span class="badge badge-pill badge-light p-2 text-13 mr-1" >{{soldeCash | number}} {{entite?.devise}}</span>
  </div>
  <div class="d-flex flex-row justify-content-around" *ngIf="guard.pass(autority?.solde_cashAn)">
    <span class="text-small mr-1 pt-1">{{'GENERAL.CASH_BALANCE' | translate}}:</span>
    <span class="badge badge-pill badge-light p-2 text-13 mr-1" >{{soldeCashAn | number}} {{entite?.devise}}</span>
  </div>
<div  class=" hidden-xs d-flex align-items-center" *ngIf="guard.pass(autority?.appro_agence)">
  <div class="d-flex flex-row justify-content-around mr-2">
    <span class="text-small mr-1 pt-1">{{'GENERAL.AGENCY' | translate}}:</span>
    <span class="badge badge-pill badge-light p-2 text-13 mr-1">{{currentAgence?.nomAgence | titlecase}}</span>
  </div>
  <div class="d-flex flex-row justify-content-around">
    <span class="text-small mr-1 pt-1">{{'GENERAL.BALANCE' | translate}}:</span>
    <span class="badge badge-pill p-2 text-13 mr-1" [ngClass]="{'badge-danger':currentAgence?.seuilAlerte > currentAgence?.solde,'badge-light':currentAgence?.solde > currentAgence?.seuilAlerte }">{{currentAgence?.solde | number}} {{entite?.devise}}</span>
  </div>
  <button class="btn btn-rounded btn-success ml-2 py-1 font-weight-500" (click)="approvisAgence()"><i-feather name="trending-up"></i-feather> Approvisionner mon solde</button>
</div>
<div class="d-flex align-items-center" *ngIf="guard.pass(autority?.appro_entite) || guard.pass(autority?.appro_comm_entite)">
  <div class="d-flex flex-row justify-content-around" *ngIf="guard.pass(autority?.appro_entite)">
    <span class="text-small mr-1 pt-1">{{'GENERAL.BALANCE' | translate}}:</span>
    <span class="badge badge-pill badge-light p-2 text-13 mr-1" [ngClass]="{'badge-danger':soldeEntite >  entite.seuilAlerte,'badge-light':soldeEntite > entite.seuilAlerte }">{{soldeEntite | number}} {{entite?.devise}}</span>
  </div>
  <button *ngIf="guard.pass(autority?.appro_entite)" class="btn btn-rounded btn-success ml-2 py-1 font-weight-500" (click)="approvisEntite()"><i-feather name="trending-up"></i-feather> {{'GENERAL.TOP_UP_BALANCE' | translate}}</button>
 <div>&nbsp;</div>
  <div class="d-flex flex-row justify-content-around" *ngIf="guard.pass(autority?.appro_comm_entite)">
    <span class="text-small mr-1 pt-1">{{'GENERAL.BALANCE_COM_ENT' | translate}}:</span>
    <span class="badge badge-pill badge-light p-2 text-13 mr-1" >{{soldeCommission | number}} {{entite?.devise}}</span>
  </div>
  <button  *ngIf="guard.pass(autority?.appro_comm_entite)" class="btn btn-rounded btn-success ml-2 py-1 font-weight-500" (click)="approvisEntiteComm()"><i-feather name="trending-up"></i-feather> Approvisionner compte commissionnement</button>
</div>
<div class=" hidden-xs d-flex align-items-center " *ngIf="guard.pass(autority1?.solde_marchand)">
  <div class="d-flex flex-row justify-content-around">
    <span class="text-small mr-1 pt-1">{{'GENERAL.BALANCE_MERCHANT' | translate}}:</span>
    <span class="badge badge-pill p-2 text-13 mr-1">{{soldeMarchand?.solde | number}} {{entite?.devise}}</span>
  </div>
</div>
</div>
