import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Constantes } from '../../constantes';
import { CaisseService } from '../../services/caisse.service';

@Component({
  selector: 'app-autocomplete-users',
  templateUrl: './autocomplete-users.component.html',
  styleUrls: ['./autocomplete-users.component.scss']
})
export class AutocompleteUsersComponent implements OnInit {
  show: boolean = false;
  userName = '';
  @Input() users: any[];
  @Output() selectedUser = new EventEmitter<string>();

  constructor(
    private _caisseService: CaisseService
  ) { }

  ngOnInit() {
  }

  showSuggestions() {
    this.show = true;
  }
  
  selectUser(index) {
    const user = this.users[index];
    this.userName = this.fullName(user);
    const userId = `/api/users/${user.id}`;
    this.selectedUser.emit(userId);
    this.show = false;
  }

  fullName(user) {
    return [user.prenom,user.nom].join(' ');
  }

}
