import {Compiler, Component, OnInit} from '@angular/core';
import { NavigationService, IMenuItem } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { User } from '../../../../models/user';
import { Entity } from 'src/app/shared/models/entity.model';
import { TranslateService } from '@ngx-translate/core';
import { Constantes } from 'src/app/shared/constantes';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import {TranslationService} from "../../../../../views/i18n";

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {

    notifications: any[];
    entite: any;
    currentUser: User;
    entity: Entity;
    nav: IMenuItem[];
    currentLang: string;
    loading: boolean;
    alertMessage: string;
    lang='';

    constructor(
      private _translateService: TranslateService,
      private navService: NavigationService,
      public searchService: SearchService,
      private _authService: AuthService,
      private _localStoreService:LocalStoreService,
      private  translate:TranslateService,
      private _permissionsService: PermissionsService,
      private _compiler: Compiler,private translateServvice:TranslationService
    ) {
      this.notifications = [
      ];
    }

    ngOnInit() {
      this.initPermissions();
      this.entite = this._localStoreService.getItem(Constantes.CURRENT_ENTITY);
      this.setPrimaryColor(this.entite.couleur);
      this.currentUser = new User(this._authService.currentUser());
      this.currentLang = this._translateService.currentLang;

      //Liste des elements du menu
      this.navService.menuItems$
			.subscribe((items) => {
        this.nav = items;
      });
        this.lang = this.translateServvice.getSelectedLanguage();
        this.translateServvice.setLanguage(this.lang);
    }

    changeLangue(langue : string){
        if(langue==='en'){
            this.lang='en'
        }
        if(langue==='fr') {
            this.lang='fr'
        }
        if(langue==='pr') {
            this.lang='pr'
        }
        this.translateServvice.setLanguage(this.lang);
        window.location.reload()
    }
    initPermissions() {
      this._permissionsService.setPermissions();
    }

    toggelSidebar() {
      const state = this.navService.sidebarState;
      if (state.childnavOpen && state.sidenavOpen) {
        return state.childnavOpen = false;
      }
      if (!state.childnavOpen && state.sidenavOpen) {
        return state.sidenavOpen = false;
      }
      if (!state.sidenavOpen && !state.childnavOpen) {
          state.sidenavOpen = true;
          setTimeout(() => {
              state.childnavOpen = true;
          }, 50);
      }
    }
    signout() {
      this._authService.signout();
    }

    approvSent(message) {
      this.alertMessage = message;
    }

    setPrimaryColor(colour) {
      const body = document.getElementsByTagName('body')[0];
      body.style.setProperty('--entity-primary', colour);
    }
}
