import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Utils } from '../utils';
import { LocalStoreService } from "./local-store.service";
import { Constantes } from '../constantes';


@Injectable({
    providedIn: 'root'
})
export class DataLayerService {
    private codeClient;     
    constructor(
        private http: HttpClient,
        private store: LocalStoreService,
    ) {
        this.codeClient = this.store.getItem(Constantes.CURRENT_CODE_ENTITY);
    }

    getCaisses() {
        // return this.http.get<any[]>('/api/users');
        return this.http.get<any[]>(environment.apiReseauUrl + '/point_de_ventes/?codeEntite=' + this.codeClient);
    }

    getUsers() {
        return this.http.get<any[]>(environment.apiAuthUrl  + '/users?codeClient=E001' );
    }

    getInvoice(id) {
        return this.http.get<any[]>('/api/users/' + id);
    }
    saveInvoice(invoice) {
        if(invoice.id) {
            return this.http.put<any[]>('/api/users/'+invoice.id, invoice);
        } else {
            invoice.id = Utils.genId();
            return this.http.post<any[]>('/api/users/', invoice);
        }
    }
    deleteInvoice(id) {
        return this.http.delete<any[]>('/api/users/'+id);
    }
    getMails() {
        return this.http.get<any[]>('/api/mails');
    }
    getCountries() {
        return this.http.get<any[]>('/api/countries');
    }
    getProducts() {
        return this.http.get<any[]>('api/products');
    }
}
