
export class Entity {
  id: number;
  codeEntite: string;
  email: string;
  couleur: string;
  fileType: string;
  tel: string;
  ninea: string;
  rc: string;
  status: boolean;
  raisonSociale: string;
  adresse: string;
  pays: string;
  secteurActivite: string;
  devise: string;
  source: string;
  dateCreation: string;
  dateModification: string;
  compteEntite: any;

  constructor(obj:any){
    this.id = obj.id || null;
    this.codeEntite = obj.codeEntite || '';
    this.email = obj.email || '';
    this.couleur = obj.couleur || '';
    this.tel = obj.tel || '';
    this.ninea = obj.ninea || '';
    this.rc = obj.rc || '';
    this.status = obj.status || true;
    this.raisonSociale = obj.raisonSociale || '';
    this.adresse = obj.adresse || '';
    this.pays = obj.pays || '';
    this.secteurActivite = obj.secteurActivite || '';
    this.devise = obj.devise || '';
    this.source = obj.source || '';
    this.dateCreation = obj.dateCreation || '';
    this.dateModification = obj.dateModification || '';
    this.fileType = obj.fileType || '';
    this.compteEntite = obj.compteEntite;
  }
}
