import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-simple-pagination',
  templateUrl: './simple-pagination.component.html',
  styleUrls: ['./simple-pagination.component.scss']
})
export class SimplePaginationComponent implements OnInit {
  @Input() totalItems: number;
  @Input() perPage: number;
  @Output() changePage = new EventEmitter<number>();
  pages = [];
  currentPage = 1;
  totalPage: number;
  
  constructor() { }
  
  ngOnInit() {
    this.totalPage = Math.ceil(this.totalItems / this.perPage)
    this.pages = Array(this.totalPage);
  }

  //Aller à la page précédente
  prev(){
    this.currentPage -= 1; 
    this.changePage.emit(this.currentPage);
  }

  //Aller à la page suivante
  next(){
    this.currentPage += 1; 
    this.changePage.emit(this.currentPage);
  }

  //Aller à la page 
  goToPage(page){
    this.currentPage = page;
    this.changePage.emit(this.currentPage);
  }

}
