<div class="modal-header bg-gray-100">
  <h3 class="modal-title" id="modal-basic-title">Approvisionnement commission entité </h3>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="approvForm">
  <div class="modal-body">
    <div class="row form-group">
      <div class="col-md-3">
        <label>Montant({{data.devise}})<span class="text-danger">*</span></label>
        <p class="text-muted">Montant</p>
      </div>
      <div class="col-md-9 px-4">
        <input type="number" [min]="0" class="form-control form-control-rounded" formControlName="montant" />
        <p class="text-small text-danger mt-1" *ngIf="approvForm.controls.montant.errors?.required && (approvForm.controls.montant.dirty || approvForm.controls.montant.touched)">Veuillez saisir le montant </p>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3">
        <label>Opération<span class="text-danger">*</span></label>
        <p class="text-muted">Opération</p>
      </div>
      <div class="col-md-9 px-4">
        <select formControlName="operation" name="operation" class="form-control">
          <option value='' selected>--Choisir</option>
          <option value='DEBIT'>Débit</option>
          <option value='CREDIT'>Crédit</option>
        </select>
        <p class="text-small text-danger mt-1" *ngIf="approvForm.controls.operation.errors?.required && (approvForm.controls.operation.dirty || approvForm.controls.operation.touched)">Veuillez saisir l' opération </p>
      </div>
    </div>
  </div>
  <div class="modal-footer bg-gray-100">
    <div class="row">
      <div class="col-md-12">
        <a class="btn btn-outline-default btn-rounded" (click)="activeModal.dismiss('Cross click')">Annuler</a>
        <button class="btn btn-primary btn-rounded ml-1" [disabled]="approvForm.invalid || loading" type="submit"  (click)="validate()" >Envoyer</button>
      </div>
    </div>
  </div>
</form>
