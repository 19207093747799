import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { Constantes } from 'src/app/shared/constantes';
import { AgenceService } from 'src/app/shared/services/agence.service';
import { EntityService } from 'src/app/shared/services/entity.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApprovisDialogComponent } from '../approvis-dialog/approvis-dialog.component';
import {Guard} from "../../../utils/Guard";
import {UserPermissions} from "../../../user_permissions";
import {ApprovisAgenceComponent} from "../approvis-agence/approvis-agence.component";
import {ApprovisEntiteCommComponent} from '../approvis-entite-comm/approvis-entite-comm.component';

@Component({
  selector: 'app-current-agence',
  templateUrl: './current-agence.component.html',
  styleUrls: ['./current-agence.component.sass']
})
export class CurrentAgenceComponent implements OnInit {
  currentAgence: any;
  currentUser: any;
  roleCurrentUser = '';
  entite: any;
  soldeEntite: any;
  soldeMarchand: any;
  soldeCash: any;
  soldeCashAn: any;
  soldeCommission: any;
  seuilSolde: any;
  autority = UserPermissions.MANAGE_APPROVISIONNEMENT.actions;
  autority1 = UserPermissions.MANAGE_MARCHANDS.actions;
  @Output() approvSent = new EventEmitter<string>();
  constructor(
    private _localStoreService:LocalStoreService,
    private _agenceService:AgenceService,
    private _entityService: EntityService,public guard: Guard,
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    const codeAgence = this._localStoreService.getItem(Constantes.CURRENT_AGENCE);
    this.entite = this._localStoreService.getItem(Constantes.CURRENT_ENTITY);
    this.currentUser = this._localStoreService.getItem(Constantes.DGC_CURRENT_USER);
    this.roleCurrentUser = this.currentUser.roles.join();
    this.initCurrentAgence();
      this.initEntitySolde();
      this.initMarchandSolde();
  }
  // initCurrentAgence(code){
  //   this._agenceService.findAgenceByCode(code)
  //   .subscribe(res => {
  //     res[Constantes.HYDRA_MEMBER].map(item => {
  //       if (item.code == code) {
  //         this.currentAgence = item;
  //         this.soldeCashAn = item.soldeCash;
  //         console.log('current',this.currentAgence)
  //         return
  //       }
  //     })
  //   }, error => {
  //     console.log(error);
  //   })
  // }
  initCurrentAgence(){
    this._agenceService.getSoldeAgence()
    .subscribe(res => {
      this.currentAgence = res;
      this.soldeCashAn = res.soldeCash;
    }, error => {
      console.log(error);
    })
  }
  initEntitySolde(){
    this._entityService.soldeEntite()
    .subscribe(res => {
      this.soldeEntite = res['solde'];
      this.soldeCash = res['soldeCash'];
      this.soldeCommission = res['soldeCommission'];
    }, error => {
      console.log(error);
    })
  }
  initMarchandSolde(){
    this._entityService.soldeMarchand()
    .subscribe(res => {
      this.soldeMarchand = res;
     // this.soldeCash = res['soldeCash'];
    }, error => {
      console.log(error);
    })
  }
  approvisAgence() {
    const dialogRef = this.modalService.open(ApprovisAgenceComponent, {centered: true, size: "lg"});
    dialogRef.componentInstance.data = {
      type:'agence',
      devise: this.entite.devise
    };

    dialogRef.result.then((res) => {
      if (!res) {
        return;
      }
      if(res.success) {
        if(res.res.status == 3){
          this.approvSent.emit("La demande d'approvissonement pour votre agence a été envoyée.Elle est en attente d'approbation de votre administrateur financier.");
        } else{
          this.approvSent.emit("Demande envoyée avec succès, en attente de validation");
        }
      }
    }).catch(e => {
      console.log(e);
    });
  }
  approvisEntite(){
    const dialogRef = this.modalService.open(ApprovisDialogComponent, {centered: true, size: "lg"});
    dialogRef.componentInstance.data = {
      type:'entite',
      devise: this.entite.devise
    };
    dialogRef.result.then((res) => {
      if (!res) {
        return;
      }
      if(res.success) {
        this.approvSent.emit("La demande d'approvissonement a été envoyé. Elle est en attente de validation de BG DIGITAL.");
      }
    }).catch(e => {
      console.log(e);
    });
  }

  approvisEntiteComm() {
    const dialogRef = this.modalService.open(ApprovisEntiteCommComponent, {centered: true, size: "lg"});
    dialogRef.componentInstance.data = {
      type:'entite',
      devise: this.entite.devise
    };
    dialogRef.result.then((res) => {
      if (!res) {
        return;
      }
      if(res.success) {
        this.approvSent.emit("La demande d'approvissonement a été envoyé. Elle est en attente de validation de BG DIGITAL.");
      }
    }).catch(e => {
      console.log(e);
    });
  }
}
