import { AgenceService } from "../services/agence.service";

export class Agence {
  id: number;
  codeEntite: string;
  nom: string;
  code:string;
  parent: Agence
  seuilAlerte:number;
  nbCaisse: number;
  email: string;
  tel: string;
  adresse: string;
  solde: number;
  soldeCash: number;
  departement: any;
  commune: any;
  status: boolean;
  heureOuverture: string;
  heureFermeture: string;
  niveau: string;
  constructor(obj: any) {
    this.id = obj.id || null;
    this.codeEntite = obj.codeEntite || '';
    this.nom = obj.nom || '';
    this.email = obj.email || '';
    this.solde = obj.solde || 0;
    this.tel = obj.tel || '';
    this.adresse = obj.adresse || '';
    this.departement = obj.departement;
    this.heureFermeture = obj.heureFermeture || '';
    this.heureOuverture = obj.heureOuverture || '';
    this.niveau = obj.niveau || '';
    this.departement = obj.departement || null;
    this.commune = obj.commune || null;
    this.seuilAlerte = obj.seuilAlerte || '';
    this.status = obj.status || '';
  }
}
