import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApprovService} from "../../../services/approv.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
interface DialogData {
  type?: string;
  devise?: string;
}

@Component({
  selector: 'app-approvis-agence',
  templateUrl: './approvis-agence.component.html',
  styleUrls: ['./approvis-agence.component.scss']
})
export class ApprovisAgenceComponent implements OnInit {

  data: DialogData;
  approvForm: FormGroup;
  loading: boolean;
  img_tab =[];
  constructor(
    private _formBuilder:FormBuilder,
    private _approvService:ApprovService,
    public activeModal:NgbActiveModal,
    private _toastrService:ToastrService
  ) { }

  ngOnInit(): void {
      this.approvForm = this._formBuilder.group({
        montant: new FormControl('', Validators.required),
        documents: new FormControl([]),
      });
  }


  validate() {
  const data= {
      montant: this.approvForm.value.montant,
      documents: this.img_tab
    }
    this.loading = true;
      this._approvService.approvisionnerAgence(data)
        .subscribe(res => {
          this.loading = false;
          this.activeModal.close({success:true, res});
        }, error => {
          this.loading = false;
          this._toastrService.error(error.error['hydra:description'], "Demande d'approvisionnement")
          console.log(error);
        })
  }
  onSelect(event) {
    this.approvForm.value.documents.push(event.addedFiles[0]);
    var reader = new FileReader();
    reader.readAsDataURL(event.addedFiles[0]);
    reader.onload = () => {
      const source: string = reader.result as string
      let doc = {source:source}
      this.img_tab.push(doc);
    }
    console.log('img_tab',this.img_tab)

  }
  onRemove(event) {
    this.approvForm.value.documents.splice(this.approvForm.value.documents.indexOf(event), 1);
    var reader = new FileReader();
    reader.readAsDataURL(event);
    reader.onload = () => {
      const source: string = reader.result as string
      this.img_tab.splice(source.indexOf(event), 1);
    }
  }

}
