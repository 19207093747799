import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PermissionsService} from './permissions.service';
import {UserPermissions} from '../user_permissions';
import {LocalStoreService} from './local-store.service';
import {Constantes} from '../constantes';
import {TranslateService} from "@ngx-translate/core";
const CODE_ENTITE ={
  AGRIDEV:'agridev'
}
export interface IMenuItem {
  id?: string;
  title?: string;
  description?: string;
  type: string;       // Possible values: link/dropDown/extLink
  name?: string;      // Used as display text for item and title for separator type
  name_entite?: NameEntity[];      // Used as display text for item and title for separator type
  icone_entite?: NameEntity[];      // Used as display text for item and title for separator type
  state?: string;     // Router state
  icon?: string;      // Material icon name
  tooltip?: string;   // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  active?: boolean;
}
export interface NameEntity{
  code:string;
  value:string;
}
export interface IChildItem {
  id?: string;
  parentId?: string;
  type?: string;
  name: string;       // Display text
  name_entite?: NameEntity[];       // Display text
  state?: string;     // Router state
  icon?: string;
  sub?: IChildItem[];
  active?: boolean;
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

interface ISidebarState {
  sidenavOpen?: boolean;
  childnavOpen?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  public menu = [];
  public codeModules = [];
  public sidebarState: ISidebarState = {
    sidenavOpen: true,
    childnavOpen: false
  };
  codeClient;
  entite
  constructor(
    private _permissionService: PermissionsService, private store: LocalStoreService,
    private _localStoreService: LocalStoreService,private translate:TranslateService
  ) {
     this.entite = this.store.getItem(Constantes.CURRENT_CODE_ENTITY);
    this._permissionService.permissionsCodes.subscribe((permissions) => {
      this.codeClient = this.store.getItem(Constantes.CURRENT_CODE_ENTITY);
      this.publishNavigationChange(permissions);
    });

  }

  defaultMenu: IMenuItem[] = this.menu;

  manageAgenceMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.AGENCIES.AGENCIES_S'),
    name_entite:[
      {
        code:CODE_ENTITE.AGRIDEV,
        value:this.translate.instant('SIDEBAR.AGENCIES.AGENCIES'),
      }
    ],
    icon: 'i-Network-Window',
    type: 'dropDown',
    sub: []
  };
  manageDistMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.DISTRIBUTORS.DISTRIBUTORS'),
    icon: 'i-Network-Window',
    type: 'dropDown',
    sub: []
  };
  manageCooperativeMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.COOPERATIVES.COOPERATIVES'),
    icon: 'i-Network-Window',
    type: 'dropDown',
    sub: []
  };
  manageMarchandMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.MERCHANTS.MERCHANTS'),
    icon: 'i-Home1',
    type: 'dropDown',
    sub: []
  };

  manageUsersMenuItem: IMenuItem = {
    icon: 'i-MaleFemale',
    name: this.translate.instant('SIDEBAR.STAFF.STAFF'),
    name_entite:[
      {
        code:CODE_ENTITE.AGRIDEV,
        value:this.translate.instant('SIDEBAR.STAFF.USERS'),
      }
    ],
    type: 'dropDown',
    sub: []
  };

  dashBoardMenutItem: IMenuItem = {
      name: this.translate.instant('SIDEBAR.DASHBOARD'),
      icon: 'i-Dashboard',
      state: '/dashboard',
      type: 'link'
    };

  manageReportingMenuItem: IMenuItem = {
      icon: 'i-Line-Chart',
      name: this.translate.instant('SIDEBAR.REPORTING.REPORTING'),
      type: 'dropDown',
      sub: []
    };
    manageWalletMenuItem: IMenuItem = {
      name: this.translate.instant('SIDEBAR.CUSTOMERS.CUSTOMERS'),
    name_entite:[
      {
        code:CODE_ENTITE.AGRIDEV,
        value:this.translate.instant('SIDEBAR.PRODUCERS.PRODUCERS'),
      }
    ],
      icon: 'i-MaleFemale',
      type: 'dropDown',
      sub: []
    };
  manageLogsMenuItem: IMenuItem = {
      name: this.translate.instant('SIDEBAR.LOGS.LOGS'),
      icon: 'i-Clock-Back',
      type: 'link',
      state: '/history'
    };

  manageMessagesMenuItem: IMenuItem = {
      name: this.translate.instant('SIDEBAR.MESSAGES.MESSAGES'),
      icon: 'i-Email',
      type: 'link',
      state: '/messages/list'
    };
  manageVersementMenuItem: IMenuItem = {
      name: this.translate.instant('SIDEBAR.PAYMENTS.PAYMENTS'),
      icon: 'i-Money-Bag',
    type: 'dropDown',
    sub: []
    };
  manageTransfersMenuItem: IMenuItem = {
      name: this.translate.instant('SIDEBAR.TRANSFERS.TRANSFERS'),
      icon: 'i-Arrow-Turn-Right',
      type: 'dropDown',
      sub: []
    };
  manageTransactionsMenuItem: IMenuItem = {
      name: this.translate.instant('SIDEBAR.TRANSACTIONS.TRANSACTIONS'),
      icon: 'i-Sync',
      type: 'dropDown',
      sub: []
    };
  manageReversementMenuItem: IMenuItem = {
      name: this.translate.instant('SIDEBAR.SUBSTANTIVE_APPEAL.SUBSTANTIVE_APPEAL'),
      icon: 'i-Coins p-1',
      type: 'dropDown',
      sub: []
    };
  manageCardsMenuItem: IMenuItem = {
      name: this.translate.instant('SIDEBAR.CARDS.CARDS'),
      icon: 'i-Folders',
      type: 'dropDown',
      sub: []
    };
  manageCampagneMenuItem: IMenuItem = {
      name: this.translate.instant('SIDEBAR.CAMPAIGNS.CAMPAIGNS'),
      icon: 'i-Folders',
      type: 'dropDown',
      sub: []
    };
  manageCaissesMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.CRATES.CRATES'),
    name_entite:[
      {
        code:CODE_ENTITE.AGRIDEV,
        value:this.translate.instant('SIDEBAR.CRATES.MERCHANT_POINTS'),
      }
    ],
    icon: 'i-Cash-register-2',
    type: 'dropDown',
    sub: []
  };
  manageLimitationMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.RESTRICTIONS.RESTRICTIONS'),
    type: 'dropDown',
    icon: 'i-Gears',
    sub: []
  };
  manageReglesMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.RULES.RULES'),
    type: 'dropDown',
    icon: 'i-Gears',
    sub: []
  };
  manageEnrolementMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.ENROLLMENT.ENROLLMENT'),
    type: 'dropDown',
    icon: 'i-Network-Window',
    sub: []
  };
  managePISTEURMenuItem: IMenuItem = {
    name:  this.translate.instant('SIDEBAR.TRACKERS.TRACKERS'),
    type: 'dropDown',
    icon: 'i-MaleFemale',
    sub: []
  };
  manageAPPROVISIONNEMENTCASHMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.CASH_SUPPLY.CASH_SUPPLY'),
    type: 'dropDown',
    icon: 'i-Triangle-Arrow-Up',
    sub: []
  };
  manageArticleMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.ITEMS.ITEMS'),
    type: 'dropDown',
    icon: 'i-Share',
    sub: []
  };
  manageSoldeMenuItem: IMenuItem = {
    name: this.translate.instant('SIDEBAR.MESSOLDE'),
    type: 'link',
    icon: 'i-Money',
    state: '/messolde'
  };
  adminMenu: IMenuItem[] = [];
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  publishNavigationChange(permissions) {
    const {modulesCodes, actionsCodes} = permissions;
    this._localStoreService.setItem(Constantes.MODULE_ACTIONS_PERMIT, permissions);
    if (!(modulesCodes && modulesCodes.length > 0)) {
      return;
    }
    if (!(actionsCodes && actionsCodes.length > 0)) {
      return;
    }
    this.adminMenu = [];
    this.dashboard(modulesCodes, actionsCodes);
    this.manageUsers(modulesCodes, actionsCodes);
    this.manageAgencies(modulesCodes, actionsCodes);
    this.manageDist(modulesCodes, actionsCodes);
    this.manageCooperative(modulesCodes, actionsCodes);
    this.manageCaisses(modulesCodes, actionsCodes);
    this.manageReporting(modulesCodes, actionsCodes);
    this.manageTransactions(modulesCodes, actionsCodes);
    this.messoldes(modulesCodes, actionsCodes);
    this.manageReversement(modulesCodes, actionsCodes);
    this.manageTransfers(modulesCodes, actionsCodes);
    this.manageMessages(modulesCodes, actionsCodes);
    this.manageWallet(modulesCodes, actionsCodes);
    this.manageCards(modulesCodes, actionsCodes);
    this.manageLogs(modulesCodes, actionsCodes);
    this.manageLimite(modulesCodes, actionsCodes);
    this.manageRegles(modulesCodes, actionsCodes);
    this.manageEnolementCaisse(modulesCodes, actionsCodes);
    this.manageCampagne(modulesCodes, actionsCodes);
    this.manageVersements(modulesCodes, actionsCodes);
    this.managePisteur(modulesCodes, actionsCodes);
    this.manageArticles(modulesCodes, actionsCodes);
    this.manageApprovisionnementCash(modulesCodes, actionsCodes);
    this.manageMarchand(modulesCodes, actionsCodes);
    this.menuItems.next(this.adminMenu);
    // console.log("menuItems: ",this.menuItems);
  }
  dashboard(modulesCodes, actionsCodes) {
    if (modulesCodes.includes(UserPermissions.MANAGE_DASHBOARD.module)) {
      this.adminMenu.push(this.dashBoardMenutItem);
    }
  }
  messoldes(modulesCodes, actionsCodes) {
    if (this.entite =='nigertelec') {
      this.adminMenu.push(this.manageSoldeMenuItem);
    }
  }
  // manage dashboard
  // manageDashboard(modulesCodes, actionsCodes) {
  //
  //   if (modulesCodes.includes(UserPermissions.MANAGE_DASHBOARD.module) && actionsCodes.includes(UserPermissions.MANAGE_DASHBOARD.actions.viewDashboard)) {
  //     this.adminMenu.push(this.dashBoardMenutItem);
  //   }
  // }

  manageTransactions(modulesCodes, actionsCodes) {
    this.manageTransactionsMenuItem.sub = [];
    if (modulesCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.module)) {
      this.adminMenu.push(this.manageTransactionsMenuItem);
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactions)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.LISTE'), state: '/transactions', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionsCltW)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.TRS_WALLET'),name_entite:[{code:CODE_ENTITE.AGRIDEV,value:'Transactions producteurs'}], state: '/transactions/trsWallet', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionsOffers)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.TRS_OFFERS'), state: '/transactions/trsOffers', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionsPending)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.TRS_WAIT'), state: '/transactions/pending', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.transfertUV) && this.codeClient != 'digicash') {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.TRSF_UV'), state: '/transactions/reversement', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.appeldefond)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.SUBSTANTIVE_APPEAL'), state: '/transactions/appel-de-fonds', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.appeldefondAg)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.SUBSTANTIVE_APPEAL_A'), state: '/transactions/appel-de-fonds-agence', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionSimbot)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.TRS_SIM'), state: '/transactions/simbot', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionPartener)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.TRS_PARTENERS'), state: '/transactions/partenaire', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionGain)) {
      this.manageTransactionsMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.TRANSACTIONS.TRS_LOTO'), state: '/transactions/gains', type: 'link'}
      );
    } if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactionInt)) {
      this.manageTransactionsMenuItem.sub.push(
        {name:this.translate.instant('SIDEBAR.TRANSACTIONS.TRS_INT'), state: '/transactions/transfertint', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewtransactionsCash)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.TRS_CASH'), state: '/transactions/cash', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSACTIONS.actions.viewTransactions)) {
      this.manageTransactionsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSACTIONS.TRS_COMMISSION'), state: '/transactions/commission', type: 'link'}
      );
    }
  }
  manageReversement(modulesCodes, actionsCodes) {
    this.manageReversementMenuItem.sub = [];
    if (modulesCodes.includes(UserPermissions.MANAGE_REVERSEMENT.module)) {
      this.adminMenu.push(this.manageReversementMenuItem);
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_REVERSEMENT.actions.appeldefond)) {
      this.manageReversementMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.SUBSTANTIVE_APPEAL.SUBSTANTIVE_CRATES'), state: '/transactions/appel-de-fonds', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_REVERSEMENT.actions.appeldefondAg)) {
      this.manageReversementMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.SUBSTANTIVE_APPEAL.SUBSTANTIVE_AGENCIES'), state: '/transactions/appel-de-fonds-agence', type: 'link'}
      );
    }
  }

  manageCaisses(modulesCodes, actionsCodes) {
    this.manageCaissesMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_CAISSES.module)) {
      this.adminMenu.push(this.manageCaissesMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CAISSES.actions.view)) {
      this.manageCaissesMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CRATES.LIST'), state: '/caisses', type: 'link'}
      );
    }
    // if (actionsCodes.includes(UserPermissions.MANAGE_CAISSES.actions.viewMarchands)  ) {
    //   this.manageCaissesMenuItem.sub.push(
    //     {name: 'Liste Marchands', state: '/caisses/marchands', type: 'link'}
    //   );
    // }

    if (actionsCodes.includes(UserPermissions.MANAGE_CAISSES.actions.viewApprovs)) {
      this.manageCaissesMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CRATES.SUPPLIES_CRATES'), state: '/caisses/approvisionnements', type: 'link'}
      );
    }
  }

  manageLimite(modulesCodes, actionsCodes) {
    this.manageLimitationMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_LIMITATION.module)) {
      this.adminMenu.push(this.manageLimitationMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_LIMITATION.actions.viewlimitGeneral)) {
      this.manageLimitationMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.RESTRICTIONS.ALL'), state: '/limitations/general', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_LIMITATION.actions.viewlimitCarte)) {
      this.manageLimitationMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.RESTRICTIONS.CARDS'), state: '/limitations/cartes', type: 'link'});
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_LIMITATION.actions.viewlimitTpe)) {
      this.manageLimitationMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.RESTRICTIONS.TPE'), state: '/limitations/tpe', type: 'link'});
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_LIMITATION.actions.viewlimitDab)) {
      this.manageLimitationMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.RESTRICTIONS.DAB'), state: '/limitations/gab', type: 'link'});
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_LIMITATION.actions.viewlimitWallet)) {
      this.manageLimitationMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.RESTRICTIONS.WALLET'), state: '/limitations/wallet', type: 'link'});
    }
  }

  manageRegles(modulesCodes, actionsCodes) {
    this.manageReglesMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_REGLES.module)) {
      this.adminMenu.push(this.manageReglesMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_REGLES.actions.viewRegles)) {
      this.manageReglesMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.RULES.LIST'), state: '/regles/liste', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_REGLES.actions.createRegle)) {
      this.manageReglesMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.RULES.EVENTS'), state: '/regles/evenements', type: 'link'});
    }
  }

  manageEnolementCaisse(modulesCodes, actionsCodes) {
    this.manageEnrolementMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_ENROLEMENT.module)) {
      this.adminMenu.push(this.manageEnrolementMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_ENROLEMENT.actions.create)) {
      this.manageEnrolementMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.ENROLLMENT.NEW'), state: '/enrollments/new', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_ENROLEMENT.actions.list)) {
      this.manageEnrolementMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.ENROLLMENT.LIST'), state: '/enrollments/list', type: 'link'});
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_ENROLEMENT.actions.createPiece)) {
      this.manageEnrolementMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.ENROLLMENT.NEW_PIECES_C'), state: '/enrollments/piece', type: 'link'});
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_ENROLEMENT.actions.listPiece)) {
      this.manageEnrolementMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.ENROLLMENT.LIST_PIECES_C'), state: '/enrollments/pieceliste', type: 'link'});
    }
  }
  manageUsers(modulesCodes, actionsCodes) {
    this.manageUsersMenuItem.sub = [];
    if (modulesCodes.includes(UserPermissions.MANAGE_USERS.module)) {
      this.adminMenu.push(this.manageUsersMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_USERS.actions.viewUsers)) {
      this.manageUsersMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.STAFF.USERS'), state: '/users/list', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_USERS.actions.viewPermissions)) {
      this.manageUsersMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.STAFF.PERMISSIONS'), state: '/users/permissions', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_USERS.actions.viewRoles)) {
      this.manageUsersMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.STAFF.ROLES'), state: '/users/roles', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_USERS.actions.lacation)) {
      this.manageUsersMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.STAFF.POINT_LOCATION'), state: '/users/location', type: 'link'}
      );
    }
  }
  manageAgencies(modulesCodes, actionsCodes) {
    this.manageAgenceMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_AGENCIES.module)) {
      this.adminMenu.push(this.manageAgenceMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_AGENCIES.actions.viewAgencies)) {
      this.manageAgenceMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.AGENCIES.AGENCIES'),name_entite:[{code:CODE_ENTITE.AGRIDEV,value:'Agence'}], state: '/points-service/agences', type: 'link'}
      );
    }
    // if (actionsCodes.includes(UserPermissions.MANAGE_AGENCIES.actions.viewDist)) {
    //   this.manageAgenceMenuItem.sub.push(
    //     {name: 'Distributeurs', state: '/points-service/distributeurs', type: 'link'}
    //   );
    // }

    if (actionsCodes.includes(UserPermissions.MANAGE_AGENCIES.actions.viewAppros)) {
      this.manageAgenceMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.AGENCIES.SUPPLIES'), state: '/points-service/approvisionnements', type: 'link'}
      );
    }
  }
  manageDist(modulesCodes, actionsCodes) {
    this.manageDistMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_DISTRIBUTEURS.module)) {
      this.adminMenu.push(this.manageDistMenuItem);
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_DISTRIBUTEURS.actions.viewDistributeurs)) {
      this.manageDistMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.DISTRIBUTORS.DISTRIBUTORS'), state: '/points-service/distributeurs', type: 'link'}
      );
    }
  }
  manageCooperative(modulesCodes, actionsCodes) {
    this.manageCooperativeMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_COOPERATIVE.module)) {
      this.adminMenu.push(this.manageCooperativeMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_COOPERATIVE.actions.viewcooparative)) {
      this.manageCooperativeMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.COOPERATIVES.LIST_COOPERATIVES'), state: '/cooperative/list', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_COOPERATIVE.actions.approcooparative)) {
      this.manageCooperativeMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.COOPERATIVES.SUPPLIES'), state: '/cooperative/appro_cooperative', type: 'link'}
      );
    }
  }

  manageReporting(modulesCodes, actionsCodes) {
    this.manageReportingMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_REPORTING.module)) {
      this.adminMenu.push(this.manageReportingMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.viewAll)) {
      this.manageReportingMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.REPORTING.ALL'), state: '/reporting/all', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.viewCredits)) {
      this.manageReportingMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.REPORTING.RECEIPTS'), state: '/reporting/encaissement', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.viewDebits)) {
      this.manageReportingMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.REPORTING.DISBURSEMENT'), state: '/reporting/decaissement', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.followAccounts)) {
      this.manageReportingMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.REPORTING.ACCOUNT_MONITORING'), state: '/reporting/compte', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.soldesTelephone)) {
      this.manageReportingMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.REPORTING.PHONE_ACCOUNT_BALANCE'), state: '/reporting/soldetel', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.messageTelephone)) {
      this.manageReportingMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.REPORTING.PHONE_SMS'), state: '/reporting/messagetel', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.situationCaisse)) {
      this.manageReportingMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.REPORTING.CASH_STATUS'), state: '/reporting/situation_caisse', type: 'link'}
      );
    }
    // if(actionsCodes.includes(UserPermissions.MANAGE_REPORTING.actions.reportingCarte)) {
    //   this.manageReportingMenuItem.sub.push(
    //     {name: 'Reporting cartes', state: '/cartes/reporting', type: 'link'}
    //   )
    // }
  }

  manageWallet(modulesCodes, actionsCodes) {
    this.manageWalletMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_WALLET.module)) {
      this.adminMenu.push(this.manageWalletMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_WALLET.actions.viewClients)) {
      this.manageWalletMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CUSTOMERS.CUSTOMERS'),name_entite:[{code:CODE_ENTITE.AGRIDEV,value:'Producteurs'}], state: '/wallet/clients', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_WALLET.actions.addClients)) {
      this.manageWalletMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CUSTOMERS.NEW_CUSTOMERS'), state: '/wallet/addClt_ng', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_WALLET.actions.viewLimitations)) {
      this.manageWalletMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CUSTOMERS.LIST_WAIT_CUSTOMERS'), state: '/wallet/fraude', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_WALLET.actions.deplafondClient)) {
      this.manageWalletMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CUSTOMERS.UNCAPPING'), state: '/wallet/deplafond', type: 'link'}
      );
    }
    // if (actionsCodes.includes(UserPermissions.MANAGE_WALLET.actions.viewPointM)) {
    //   this.manageWalletMenuItem.sub.push(
    //     {name: 'Points Marchands', state: '/wallet/point_marchand', type: 'link'}
    //   );
    // }


  }

  manageLogs(modulesCodes, actionsCodes) {

    if (modulesCodes.includes(UserPermissions.MANAGE_LOGS.module) && actionsCodes.includes(UserPermissions.MANAGE_LOGS.actions.viewLogs)) {
      this.adminMenu.push(this.manageLogsMenuItem);
    }
  }

  manageMessages(modulesCodes, actionsCodes) {
    if (modulesCodes.includes(UserPermissions.MANAGE_LOGS.module) && actionsCodes.includes(UserPermissions.MANAGE_LOGS.actions.viewMessages)) {
      this.adminMenu.push(this.manageMessagesMenuItem);
    }
  }
  manageVersements(modulesCodes, actionsCodes) {
    this.manageVersementMenuItem.sub = [];
    if (modulesCodes.includes(UserPermissions.MANAGE_VERSEMENTS.module)) {
      this.adminMenu.push(this.manageVersementMenuItem);
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_VERSEMENTS.actions.addVersementsB)) {
      this.manageVersementMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.PAYMENTS.NEW_PAYMENTS_B'), state: '/versements/add', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_VERSEMENTS.actions.addAppeldefond)) {
      this.manageVersementMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.PAYMENTS.NEW_APPEAL'), state: '/versements/addAgence/agence', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_VERSEMENTS.actions.addVersementAgence)) {
      this.manageVersementMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.PAYMENTS.NEW_PAYMENTS_A'), state: '/versements/addAgence/autre', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_VERSEMENTS.actions.viewVersements)) {
      this.manageVersementMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.PAYMENTS.LIST_PAYMENTS'), state: '/versements', type: 'link'}
      );
    }

  }

  manageTransfers(modulesCodes, actionsCodes) {
    this.manageTransfersMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_TRANSFERS.module)) {
      this.adminMenu.push(this.manageTransfersMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSFERS.actions.viewCancels)) {
      this.manageTransfersMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSFERS.CANCELATION'), state: '/Transfert/annulation', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSFERS.actions.viewRefunds)) {
      this.manageTransfersMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSFERS.REFUND'), state: '/Transfert/remboursement', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_TRANSFERS.actions.viewFundsTransfers)) {
      this.manageTransfersMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRANSFERS.TRANSFERS_FUND'), state: '/Transfert/a-to-a-transfers', type: 'link'}
      );
    }
  }

  manageCards(modulesCodes, actionsCodes) {
    this.manageCardsMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_CARDS.module)) {
      this.adminMenu.push(this.manageCardsMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.agencesDistrib)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.DISTRIBUTIONS_A'), state: '/cartes/distribution', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.caissesDistrib)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.DISTRIBUTIONS_C'), state: '/cartes/distributionCaisse', type: 'link'}
      );
    }


    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.received)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.RECEPTIONS'), state: '/cartes/reception', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.cardsPrices)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.PRICE_CARDS'), state: '/cartes/price', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.availableCards)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.LISR_CARDS'), state: '/cartes/stock', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.cartesbloquees)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.BLOCKED_CARDS'), state: '/cartes/bloque', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.retourCaisse)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.BACK_CARDS_C'), state: '/cartes/retour/caisse', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.retourAgence)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.BACK_CARDS_A'), state: '/cartes/retour/agence', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.receivedAgence)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.RECEPTIONS_CARDS_A'), state: '/cartes/reception/agence', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.approvs)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.SUPPLIES'), state: '/cartes/appro', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.search)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.RESEARCH_CARDS') , state: '/cartes/recherche', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CARDS.actions.reporting)) {
      this.manageCardsMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CARDS.REPORTING_CARDS'), state: '/cartes/reporting', type: 'link'}
      );
    }
  }
  manageCampagne(modulesCodes, actionsCodes) {
    this.manageCampagneMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_CAMPAGNE.module)) {
      this.adminMenu.push(this.manageCampagneMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_CAMPAGNE.actions.viewCampagne)) {
      this.manageCampagneMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CAMPAIGNS.LIST_CAMPAIGNS'), state: '/campagne/list', type: 'link'}
      );
    }
  }
  manageArticles (modulesCodes, actionsCodes) {
    this.manageArticleMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_ARTICLESS.module)) {
      this.adminMenu.push(this.manageArticleMenuItem);
    }
    // if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.viewArticles)) {
    //   this.manageArticleMenuItem.sub.push(
    //     {name: 'Liste des articles', state: '/articles', type: 'link'}
    //   );
    // }
    if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.viewDistributionAg)) {
      this.manageArticleMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.ITEMS.lIST_DISTRIBUTION'), state: '/articles/list', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.viewDistributionAg)) {
      this.manageArticleMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.ITEMS.lIST_BOOKLETS'), state: '/articles/list_livret', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.newDistributionAg)) {
      this.manageArticleMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.ITEMS.NEW_DISTRIBUTIONS_A'), state: '/articles/dist_agence', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.newDistributionAc)) {
      this.manageArticleMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.ITEMS.NEW_DISTRIBUTIONS_C'), state: '/articles/dist_caisse', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_ARTICLESS.actions.viewStock)) {
      this.manageArticleMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.ITEMS.INVENTOR_DISTRIBUTION'), state: '/articles/stock', type: 'link'}
      );
    }
  }
  manageMarchand (modulesCodes, actionsCodes) {
    this.manageMarchandMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_MARCHANDS.module)) {
      this.adminMenu.push(this.manageMarchandMenuItem);
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_MARCHANDS.actions.uploadMarchand)) {
      this.manageMarchandMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.MERCHANTS.FILE_LOADING'), state: '/marchands/upload_marchand', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_MARCHANDS.actions.viewMarchand)) {
      this.manageMarchandMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.MERCHANTS.LIST_MERCHANTS'), state: '/marchands/list_marchand', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_MARCHANDS.actions.viewCodeMarchand)) {
      this.manageMarchandMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.MERCHANTS.LIST_CODE_MERCHANTS'), state: '/marchands/liste_codemarchand', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_MARCHANDS.actions.viewAppelFond)) {
      this.manageMarchandMenuItem.sub.push(
        {name:  this.translate.instant('SIDEBAR.MERCHANTS.LIST_APPEAL'), state: '/marchands/appel_fond', type: 'link'}
      );
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_MARCHANDS.actions.viewTransactions)) {
      this.manageMarchandMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.MERCHANTS.LIST_TRANSACTIONS'), state: '/marchands/transaction', type: 'link'}
      );
    }

  }
  managePisteur(modulesCodes, actionsCodes) {
    this.managePISTEURMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_PISTEUR.module)) {
      this.adminMenu.push(this.managePISTEURMenuItem);
    }

    if (actionsCodes.includes(UserPermissions.MANAGE_PISTEUR.actions.viewpisteur)) {
      this.managePISTEURMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRACKERS.LIST_TRACKERS'), state: '/pisteur/list', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_PISTEUR.actions.viewappropisteur)) {
      this.managePISTEURMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRACKERS.SUPPLIES'), state: '/pisteur/appro', type: 'link'}
      );
    }if (actionsCodes.includes(UserPermissions.MANAGE_PISTEUR.actions.viewreverspisteur)) {
      this.managePISTEURMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.TRACKERS.SUBSTANTIVE_APPEAL'), state: '/pisteur/appeldefond', type: 'link'}
      );
    }
  }

  manageApprovisionnementCash(modulesCodes, actionsCodes) {
    this.manageAPPROVISIONNEMENTCASHMenuItem.sub = [];

    if (modulesCodes.includes(UserPermissions.MANAGE_APPROVISIONNEMENTCASH.module)) {
      this.adminMenu.push(this.manageAPPROVISIONNEMENTCASHMenuItem);
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_APPROVISIONNEMENTCASH.actions.viewapprocashAgence)) {
      this.manageAPPROVISIONNEMENTCASHMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CASH_SUPPLY.CASH_SUPPLY_A'), state: '/approvisionnements/list', type: 'link'}
      );
    }
    if (actionsCodes.includes(UserPermissions.MANAGE_APPROVISIONNEMENTCASH.actions.viewapprocashCaisse)) {
      this.manageAPPROVISIONNEMENTCASHMenuItem.sub.push(
        {name: this.translate.instant('SIDEBAR.CASH_SUPPLY.CASH_SUPPLY_C'), state: '/approvisionnements/approcaisse', type: 'link'}
      );
    }
  }
}
