import { Group } from "./group.model";

export class User {
	id: number;
  email: string;
  tel: string;
  nom: string;
  prenom: string;
  dateCreation: string;
  firstName: string;
  lastName: string;
  username: string;
  nomAgence: string;
  codeClient: string;
  telephone: string;
  groups: Group[] = [];
  status: boolean;
  profiles: any;
  codeAgence: string;
  bureau: string;
  caisse: string;
  agence: string;
  profils: any;
  source: string;
  photo: string;
  auteur: string;
  auteurValidation: string;


  constructor(user:any) {
  	this.id = user.id || null;
  	this.email = user.email || '';
    this.firstName = user.prenom || '';
    this.caisse = user.caisse || '';
    this.source = user.source || '';
    this.username = user.username || '';
  	this.lastName = user.nom || '';
    this.codeClient = user.codeClient || '';
    this.telephone = user.tel || '';
    this.status = user.status;
    this.profiles = user.profiles || [];
    this.codeAgence = user.agence || '';
    this.bureau = user.bureau || '';
    this.nomAgence = user.nomAgence || '';
    this.agence = user.agence || '';
    this.source = user.source || '';
    this.profils = user.profils || [];
    this.photo = user.photo || '';
    this.auteur = user.auteur || '';
  }

  public get fullName():string {
  	return [this.firstName, this.lastName].join(' ');
  }

  public get roles(): string {
    let roles = '';

    this.profiles.map(item => {
      roles += item.nom;
    })

    this.profils.map(item => {
      roles += item;
    })

    return roles;
  }
}
