import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchModule } from './components/search/search.module';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import {TranslateModule} from '@ngx-translate/core';
import {TranslationService} from "../views/i18n";
import {locale as frLang} from "../views/i18n/vocabs/fr";
import {locale as enLang} from "../views/i18n/vocabs/en";
import {locale as prLang} from "../views/i18n/vocabs/pr";
@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    SearchModule,
    ToastrModule.forRoot(),
    NgbModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    SharedPipesModule,
    RouterModule,
    TranslateModule.forChild()
  ]
})
export class SharedModule {
  constructor( private translationService: TranslationService) {
    this.translationService.loadTranslations(
      frLang,
      enLang,
      prLang
    );
  }
}
