import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserPermissions} from "../../../shared/user_permissions";
import {LocalStoreService} from "../../../shared/services/local-store.service";
import {AgenceService} from "../../../shared/services/agence.service";
import {EntityService} from "../../../shared/services/entity.service";
import {Guard} from "../../../shared/utils/Guard";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Constantes} from "../../../shared/constantes";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-mes-soldes',
  templateUrl: './mes-soldes.component.html',
  styleUrls: ['./mes-soldes.component.scss']
})
export class MesSoldesComponent implements OnInit {

  currentAgence: any;
  currentUser: any;
  roleCurrentUser = '';
  entite: any;
  soldeEntite: any;
  soldeMarchand: any;
  soldeCash: any;
  soldeCashAn: any;
  soldeCommission: any;
  seuilSolde: any;
  autority = UserPermissions.MANAGE_APPROVISIONNEMENT.actions;
  autority1 = UserPermissions.MANAGE_MARCHANDS.actions;
  @Output() approvSent = new EventEmitter<string>();
  codPinForm: FormGroup;
  constructor(
    private _localStoreService:LocalStoreService,
    private _agenceService:AgenceService,
    private _entityService: EntityService,public guard: Guard,
    private modalService:NgbModal, private fb: FormBuilder, private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    const codeAgence = this._localStoreService.getItem(Constantes.CURRENT_AGENCE);
    this.entite = this._localStoreService.getItem(Constantes.CURRENT_ENTITY);
    this.currentUser = this._localStoreService.getItem(Constantes.DGC_CURRENT_USER);
    this.roleCurrentUser = this.currentUser.roles.join();
    this.initCurrentAgence();
    this.initEntitySolde();
    this.initMarchandSolde();
    this.codPinForm = this.fb.group({
      montant: [0, Validators.required],
    });
  }
  // initCurrentAgence(code){
  //   this._agenceService.findAgenceByCode(code)
  //   .subscribe(res => {
  //     res[Constantes.HYDRA_MEMBER].map(item => {
  //       if (item.code == code) {
  //         this.currentAgence = item;
  //         this.soldeCashAn = item.soldeCash;
  //         console.log('current',this.currentAgence)
  //         return
  //       }
  //     })
  //   }, error => {
  //     console.log(error);
  //   })
  // }
  initCurrentAgence(){
    this._agenceService.getSoldeAgence()
      .subscribe(res => {
        this.currentAgence = res;
        this.soldeCashAn = res.soldeCash;
      }, error => {
        console.log(error);
      })
  }
  initEntitySolde(){
    this._entityService.soldeEntite()
      .subscribe(res => {
        this.soldeEntite = res['solde'];
        this.soldeCash = res['soldeCash'];
        this.soldeCommission = res['soldeCommission'];
      }, error => {
        console.log(error);
      })
  }
  initMarchandSolde(){
    this._entityService.soldeMarchand()
      .subscribe(res => {
        this.soldeMarchand = res;
        // this.soldeCash = res['soldeCash'];
      }, error => {
        console.log(error);
      })
  }



  transfert(content) {
    const dialogRef = this.modalService.open(content, {centered: true});
    dialogRef.result
      .then((res) => {
        if (!res) {
          return;
        }
      }).catch(e => {

    });
  }
  loading=false
  validation() {
    this.loading = true;
    const data = {
      montant: this.codPinForm.value.montant,
    };
    this._agenceService.transfertComm(data)
      .subscribe(
        (res: any) => {
          this.modalService.dismissAll();
          this.toastr.success('Transfert réussir', 'Transfert commission !', {timeOut: 3000});
          this.loading = false;
          this.initCurrentAgence();
          this.initEntitySolde();
        },
        error => {
          console.log(error);
          this.toastr.error(error.error['hydra:description'], 'Erreur!', {timeOut: 3000});
          this.modalService.dismissAll();
          this.loading = false;
        }
      );
  }

  close() {
    this.modalService.dismissAll();
  }
}
