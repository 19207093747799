import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LocalStoreService } from "./local-store.service";
import { Constantes } from '../constantes';
import { Entity } from '../models/entity.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EntityService {
  codeClient: string;
  private entitySource = new BehaviorSubject(new Entity({}));
  currentEntity = this.entitySource.asObservable();

  constructor(
    private http: HttpClient,
    private store: LocalStoreService) {
      this.codeClient = this.store.getItem(Constantes.CURRENT_CODE_ENTITY);
    }

  //Change current entity
  changeEntity(entity: Entity) {
    this.entitySource.next(entity);
  }

  //Recupere une entite avec le code
  getEntityByCode(codeClient = this.codeClient, jwtToken = ''){
    if(jwtToken) {
      let headers = new HttpHeaders().set('X-AUTH-TOKEN',jwtToken);
      return this.http.get<Entity>(`${environment.apiAdminUrl}/entites?codeEntite=${codeClient}`, {headers:headers});
    }
    return this.http.get<Entity>(`${environment.apiAdminUrl}/entites?codeEntite=${codeClient}`);
  }
  getChartEntite(codeEnt:string){
    return this.http.get<Entity>(`${environment.apiAdminUrl}/entites/charte?codeEntite=${codeEnt}`);

  }

  getEntityDetails(){
    return this.http.get<Entity>(`${environment.apiAdminUrl}/entites?/api/entities/details`);
  }

  //Recuperer une entite
  getEntity(id){
    return this.http.get<Entity[]>(`${environment.apiAdminUrl}/entites/${id}`);
  }


  //Transaction bilan
  getEntiteBilanTransactions() {
    return this.http.get<any[]>(environment.apiReseauUrl + '/transactions/bilan');
  }

  //Get bilan commissions
  getBilanCommissions() {
    return this.http.get<Entity[]>(environment.apiReseauUrl + '/commissions/bilan');
  }

  //Load transactions
  getTransaction(id) {
    return this.http.get<any[]>(environment.apiReseauUrl + '/transactions/'+id);
  }

  //Get menus by module
  getModules() {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/modules`);
  }

  getAuthModules() {
    return this.http.get<any[]>(`${environment.apiAuthUrl}/modules`);
  }

  all(page = 1 ) {
    return this.http.get<Entity[]>(`${environment.apiAdminUrl}/entites?itemsPerPage=12&_page=${page}`);
  }

  // save permission
  savePermission(permission) {
    permission['codeEntite'] = this.codeClient;
    if(permission.id) {
      return this.http.put<any[]>(`${environment.apiReseauUrl}/permissions/${permission.id}`, permission);
    } else {
      return this.http.post<any[]>(`${environment.apiReseauUrl}/permissions`, permission);
    }
  }

   //Sauvegarder une enitité
   saveEntity(id, data) {
    return this.http.put<any[]>(`${environment.apiAdminUrl}/entities/${id}`, data);
  }

  //Save color
  saveColor(params) {
    return this.http.put<any[]>(`${environment.apiAdminUrl}/entities/ajout/couleur`, params);
  }

  getRules() {
    return this.http.get<any[]>(`${environment.apiTransfertUrl}/regle_entites`);
  }

  updateRule(id, params) {
    return this.http.put<any[]>(`${environment.apiTransfertUrl}/regle_entites/${id}`, params);
  }

  soldeEntite() {
    return this.http.get<any[]>(`${environment.apiReseauUrl}/entites/solde`);
  }

  soldeMarchand() {
    return this.http.get<any[]>(`${environment.apiWalletUrl}/marchands/solde`);
  }
}
