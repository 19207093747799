import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {LocalStoreService} from './local-store.service';
import {Constantes} from '../constantes';
import {BehaviorSubject} from 'rxjs';
import {UserPermissions} from '../user_permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private _permissionsCodes = new BehaviorSubject<any>({ modulesCodes: [], actionsCodes: [] });
  private dataStore: { _permissionsCodes: any } = { _permissionsCodes: { modulesCodes: [], actionsCodes: [] } }; // store our data in memory
  readonly permissionsCodes = this._permissionsCodes.asObservable();

  constructor(
    private http: HttpClient,
    private _localStoreService: LocalStoreService,
  ) {
   }

  getCurrentUserPermissions(jwtToken = '') {
    if(jwtToken) {
      let headers = new HttpHeaders().set('X-AUTH-TOKEN',jwtToken);
      return this.http.get(`${environment.apiReseauUrl}/permissions/mespermissions`, {headers:headers});
    }
    return this.http.get(`${environment.apiReseauUrl}/permissions/mespermissions`);
  }

  setPermissions() {
    const currentUser = this._localStoreService.getItem(Constantes.DGC_CURRENT_USER);
    const jwtToken =  this._localStoreService.getItem(Constantes.DGC_JWT_TOKEN);
    const role = currentUser && currentUser.profils ? currentUser.profils.join('').toLowerCase() : '';
    let usercodeModules = [];
    let actionsCodes = [];
    this.getCurrentUserPermissions(jwtToken)
    .subscribe(res => {
      res['permissions'].map(item => {
        actionsCodes.push(item.codeAction);
        if (!usercodeModules.includes(item.codeModule)) {
          usercodeModules.push(item.codeModule);
        }
      });

      if (role == 'administrateur' && !usercodeModules.includes(UserPermissions.MANAGE_USERS.module)) {
        usercodeModules.push(UserPermissions.MANAGE_USERS.module);
      }
      if( role == 'administrateur' && !actionsCodes.includes(UserPermissions.MANAGE_USERS.actions.viewPermissions)) {
        actionsCodes.push(UserPermissions.MANAGE_USERS.actions.viewPermissions);
      }
      this.dataStore._permissionsCodes = {
        modulesCodes: usercodeModules,
        actionsCodes: actionsCodes
      };
      this._permissionsCodes.next(Object.assign({}, this.dataStore)._permissionsCodes);
    });
  }
  destroy(){
    this._permissionsCodes =  new BehaviorSubject<any[]>([]);
  }
}
