import {Component} from '@angular/core';
import {BnNgIdleService} from 'bn-ng-idle';
import {AuthService} from './shared/services/auth.service';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from "@ng-idle/keepalive";
import {TranslationService} from "./views/i18n";
import { locale as frLang } from './views/i18n/vocabs/fr';
import { locale as enLang } from './views/i18n/vocabs/en';
// import {Keepalive} from '@ng-idle/keepalive'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  // title = 'bootDash';
  // entite: any;
  //
  // constructor(private bnIdle: BnNgIdleService,
  //             private _authService: AuthService,) {
  //
  //   // this.bnIdle.startWatching(1500).subscribe((isTimedOut: boolean) => {
  //   //   if (isTimedOut) {
  //   //     this._authService.signout();
  //   //   } else {
  //   //     this.bnIdle.resetTimer()
  //   //     console.log('tesr')
  //   //   }
  //   // });
  //
  //
  // }

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(private idle: Idle, private translationService: TranslationService,
              private keepalive: Keepalive,
              private _authService: AuthService) {
    this.translationService.loadTranslations(
      frLang,
      enLang
    );
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(900);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this._authService.signout();
    });
    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    keepalive.interval(900);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }



}
