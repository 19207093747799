import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApprovService} from '../../../services/approv.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

interface DialogData {
  type?: string;
  devise?: string;
}
@Component({
  selector: 'app-approvis-entite-comm',
  templateUrl: './approvis-entite-comm.component.html',
  styleUrls: ['./approvis-entite-comm.component.scss']
})
export class ApprovisEntiteCommComponent implements OnInit {
  data: DialogData;
  approvForm: FormGroup;
  loading: boolean;
  fileName;
  constructor(private _formBuilder:FormBuilder,
              private _approvService:ApprovService,
              public activeModal:NgbActiveModal,
              private _toastrService:ToastrService) { }

  ngOnInit(): void {
    this.approvForm = this._formBuilder.group({
      montant: new FormControl('', Validators.required),
      operation: new FormControl('', Validators.required)
    })
  }

  validate() {
    this.loading = true;
      this._approvService.approvisionnerEntiteCommis(this.approvForm.value)
        .subscribe(res => {
          this.loading = false;
          this.activeModal.close({success:true});
        }, error => {
          this.loading = false;
          this._toastrService.error("Echec lors de l'envoi de la demande. Veuillez réessayer.", "Demande d'approvisionnement")
          console.log(error);
        })
  }

}
