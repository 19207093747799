import {Compiler, Injectable} from '@angular/core';
import { LocalStoreService } from './local-store.service';
import { Router } from '@angular/router';
import { HttpClient} from '@angular/common/http';
import { Constantes } from '../constantes';
import {PermissionsService} from './permissions.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated = true;
  constructor(private store: LocalStoreService,
              private router: Router,
              private http: HttpClient,
              private permissionsServie: PermissionsService,
              private _compiler: Compiler) {
          this.checkAuth();
  }
  checkAuth() {
    this.authenticated = this.store.getItem(Constantes.LOGIN_STATUS);
  }
  getJwtToken() {
    return this.store.getItem(Constantes.DGC_JWT_TOKEN);
  }
  getCodeEntite() {
    return this.store.getItem(Constantes.CURRENT_CODE_ENTITY);
  }
  setAuthenticated(value) {
    this.authenticated = value;
  }
  signin(credentials) {
    return this.http.post<any>(`${environment.apiAuthUrl}/login`, credentials);
  }
  storeItem(tag, value) {
    this.store.setItem(tag, value);
  }
  getItem(tag) {
    return this.store.getItem(tag);
  }
  getCurrentUser() {
    return this.store.getItem(Constantes.DGC_CURRENT_USER);
  }
  signout() {
    this.authenticated = false;
    this.store.setItem(Constantes.DGC_JWT_TOKEN, null);
    this.store.setItem(Constantes.DETAILS_ENTITE, null);
    this.store.setItem(Constantes.LOGIN_STATUS, false);
    this.store.setItem(Constantes.DGC_CURRENT_USER, null);
    this.store.setItem(Constantes.SERVICES_ENTITE, null);
    this.store.setItem(Constantes.CURRENT_ENTITY, null);
    this.store.setItem(Constantes.CURRENT_CODE_ENTITY, null);
    this.store.setItem(Constantes.CURRENT_AGENCE, null);
    this.store.clear();
    this.router.navigate(['/sessions/signin'])
      .then(() => {
        window.location.reload();
      });
    // this.router.navigateByUrl('/sessions/signin');
    this.permissionsServie.destroy();
    this._compiler.clearCache();
  }

  currentUser(): string {
    return this.store.getItem(Constantes.DGC_CURRENT_USER);
  }


  getUser(id) {
   return this.http.get<any[]>(`${environment.apiAuthUrl}/users/entite?id=${id}`);
  }

  validateRecaptcha(response) {
    // const params = {
    //   secret: `${environment.recaptchasecreteKey}`,
    //   response: response
    // }
    // return this.http.post<any>(`${environment.apiAuthUrl}/users/recaptcha`, params);
  }
  getMessage(page = 1, query){
    return this.http.get<any[]>(`${environment.apiReseauUrl}/messages?_page=${page}${query}`);
  }
  getMessageEnCours(page = 1, query){
    return this.http.get<any[]>(`${environment.apiReseauUrl}/messages/encours${query}`);
  }
  updateMessage(data){
    return this.http.put<any[]>(`${environment.apiReseauUrl}/messages/${data.id}`, data);
  }
  addMessage(data){
    return this.http.post<any[]>(`${environment.apiReseauUrl}/messages`, data);
  }
}
