import { JSONValidator } from "ngx-custom-validators/src/app/json/directive";

export class Constantes {
    static DGC_JWT_TOKEN = 'dgc_jwt_token';
    static DGC_CURRENT_USER = 'dgc_current_user';
    static DGC_CURRENT_USER_ROLES = 'dgc_current_user_roles';
    static CURRENT_CODE_ENTITY = 'dgc_current_code_entity';
    static CURRENT_AGENCE = 'dgc_current_code_agence';
    static LOGIN_STATUS = 'dgc_login_status';
    static SLASH = '/';
    static PER_PAGE = 10;
    static AUTH_TOKEN = 'X-AUTH-TOKEN';
    static HYDRA_MEMBER = 'hydra:member';
    static SERVICES_ENTITE = 'services_entite';
    static DETAILS_ENTITE = 'details_entite';
    static AUTH_SERVICE = 'auth_service';
    static ADMIN_SERVICE = 'admin_service';
    static NETWORK_SERVICE = 'network_service';
    static UPDATE_PERMISSIONS_PATH = '/permissions/entity';
    static HYDRA_DESCRIPTION = 'hydra:description';
    static DEVISE = 'XOF';
    static HYDRA_TOTAL_ITEMS = 'hydra:totalItems';
    static RESULTAT = 'resultat';
    static DATE_FORMAT = 'medium';
    static DEBIT = 'debit';
    static CREDIT = 'credit';
    static ACTIVATED = 'Activé avec succés';
    static FAILED_ACTIVATION = 'Erreur activiation';
    static SENT_SUCCESS = 'Envoyé(e) avec succés';
    static CREATE_SUCCESS = 'Crée(e) avec succés';
    static CREATE_FAIL = 'Echec lors de la création';
    static FAILED_PROCESS = 'Erreur lors du traitement';
    static CURRENT_ENTITY = 'current_entity';
    static DELETE_SUCCESS = 'Supprimé(e) avec succés';
    static DELETE_FAIL = 'Echec lors de la suppression';
    static MODULE_ACTIONS_PERMIT = '__module_action_permit__';
    static CODE_PLATEFORME_RESEAUX = 'reseau';

    static getSouscriptionsURI(codeClient) {
      return '/souscriptions/' + codeClient + '/services';
    }
}
