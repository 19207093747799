import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelTitleCase'
})
export class CamelTitleCasePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value == "string") {
      const result = value.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    } else 
      return value;
  }

}
